/*
 * Copyright 2021 Levo, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Table } from "antd";



const UserDirectory = ({ userDirectory }) => {

  const columns = [
    {
      key: "user-id",
      render: (value, record) => record.id,
      title: "User ID",
    },
    {
      key: "user-email",
      render: (value, record) => record.email,
      title: "User Email",
    },
    {
      key: "user-phone",
      render: (value, record) => record.number,
      title: "User Phone Number",
    },
    {
      key: "member-since",
      render: (value, record) => record.createdOn,
      title: "Member Since",
    },
  ];

  return <Table
    rowKey="id"
    columns={columns}
    dataSource={userDirectory}
    pagination={false}
  />;
};

export default (UserDirectory);
