/*
 * Copyright 2022 Levo, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import ApiExplorer from "../../components/apiExplorer/apiExplorer";


const ApiExplorerContainer = ({ history, accessToken }) => {

  return (
    <div>
      <ApiExplorer
        history={history}
        accessToken={accessToken}
      />
    </div>
  );
};

const mapStateToProps = ({ userReducer: { accessToken } }) => {
  return { accessToken };
};


ApiExplorerContainer.propTypes = {
  accessToken: PropTypes.string,
  history: PropTypes.object,
};

export default connect(mapStateToProps)(ApiExplorerContainer);
