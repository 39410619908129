/*
 * Copyright 2020 Traceable, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as jwt from "jsonwebtoken";

export const getMapUrl = (lat, long) => `https://maps.google.com/maps?q=${lat},${long}&output=embed`;

export const isAccessTokenValid = (token) => {
  const decoded = jwt.decode(token);
  const currentTime = Math.floor(new Date().getTime() / 1000);
  if (decoded && currentTime < decoded.exp) return true;
  return false;
};

export const formatDateFromIso = (isoDate) => {
  const date = new Date(isoDate);
  return date.toDateString();
};